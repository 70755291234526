import React from "react"
import { map, uniq, find } from "lodash"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import DayDot from "../components/day_dot"
import SEO from "../components/seo"
import dayjs from "dayjs"

var getDaysArray = function(start, end) {
  const count = dayjs(end).diff(start, "day") + 1

  let startDate = new Date(start)
  for (var arr = [], i = 0; i < count; i++) {
    arr.push(new Date(startDate))
    startDate.setDate(startDate.getDate() + 1)
  }
  return arr
}

const IndexPage = ({ data, pageContext: { folder } }) => {
  let datesWithPosts = uniq(
    map(data.allS3ImageAsset.nodes, "EXIF.DateCreatedISO")
  )
  var daylist = getDaysArray(
    datesWithPosts[0],
    datesWithPosts[datesWithPosts.length - 1]
  )
  const days = daylist.map((date, key) => {
    const post = find(data.allS3ImageAsset.nodes, function(d) {
      return d.EXIF.DateCreatedISO === date.toISOString().slice(0, 10)
    })
    return <DayDot folder={folder} key={date} post={post} date={date} />
  })

  return (
    <Layout
      header={`Every day by ${folder[0].toUpperCase()}${folder.slice(1)}`}
    >
      <SEO
        title={`A photo a day by ${folder[0].toUpperCase()}${folder.slice(1)}`}
      />
      <section className="section day-dots-section">
        <ol className="day-dots">{days}</ol>
      </section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query($keyRegex: String) {
    allS3ImageAsset(
      sort: { fields: [EXIF___DateCreatedISO], order: ASC }
      filter: { Key: { regex: $keyRegex } }
    ) {
      nodes {
        EXIF {
          DateCreatedISO
        }
        childImageSharp {
          fixed(fit: CONTAIN, width: 200) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  }
`
