import { Link } from "gatsby"
import React from "react"
import Img from "gatsby-image"

const DayDot = ({ date, post, folder }) => {
  const formattedDate = date.toISOString().slice(0, 10)
  const link = `/${folder}/${formattedDate}`
  return (
    <li className="day-dot">
      {post && (
        <Link to={link}>
          <Img fixed={post.childImageSharp.fixed} />
        </Link>
      )}
    </li>
  )
}

export default DayDot
